import $ from 'jquery'
import _ from 'lodash'

let lastId = 0;

const GENDER_OBJ = {
    MAN: "Муж",
    WOMAN: "Жен",
}

let GeneralHelper = {

    /**
     * Получает ФИО в сокращённом враианте (Фамилия И. О.)
     * @param {object} person - объект с ключами name, lastName, secondName
     * @return {string}
     */
    getFio: function (person) {
        var fio = '';
        if (!!person?.lastName) {
            fio += person.lastName
        } else if (!!person?.last_name) {
            fio += person.last_name;
        }
        if (!!person?.name) {
            fio += ' ' + String(person.name)[0].toUpperCase() + '.';
        }

        if (!!person?.secondName) {
            fio += ' ' + String(person.secondName)[0].toUpperCase() + '.';
        } else if (!!person?.second_name) {
            fio += ' ' + String(person.second_name)[0].toUpperCase() + '.';
        }

        return fio;
    },

    /**
     * Получает полный аддрес через запятую
     * @param {object} person - объект с ключами personalCity, personalStreet, personalHome, personalHousing, personalApartment
     * @return {string}
     */
    getAddress: function (person) {
        let address = '';
        const addAddress = (value) => {
            if (!!value) {
                if (!!address.length) address += ', '
                address += value
            }
        }
        addAddress(person.personalCity)
        addAddress(person.personalStreet)
        addAddress(person.personalHome)
        addAddress(person.personalHousing)
        addAddress(person.personalApartment)
        return address
    },

    /**
     * Приводит первую букву к верхнему регистру
     * @param string
     * @return {string}
     */
    ucfirst: function (string) {
        return string.length > 0 ? (string[0].toUpperCase() + string.substr(1)) : '';
    },

    /**
     * Приводит первую букву к нижнему регистру
     * @param string
     * @return {*}
     */
    lcfirst: function (string) {
        return string.length > 0 ? (string[0].toLowerCase() + string.substr(1)) : '';
    },

    /**
     * Получает ФИО в полном варианте (Фамилия Имя Отчество)
     * @param {object} person - объект с ключами name, lastName, secondName
     * @return {string}
     */
    getFullName: function (person) {
        var fullName = '';
        if (person.lastName) fullName += person.lastName;
        if (person.name) fullName += ' ' + person.name;
        if (person.secondName) fullName += ' ' + person.secondName;
        return fullName.length === 0 ? undefined : fullName;
    },

    /**
     * Возвращает корректную форму слова с числом, например 1 год, 2 года, 5 лет
     * @param {number} number - число
     * @param {string[]} variants - массив с вариантами слова, например ['год', 'года', 'лет']
     * @return {string}
     */
    getCountString: function (number, variants) {
        const tens = Math.floor(number / 10) % 10;
        const units = number % 10;
        let variantIndex = 2;

        if (tens !== 1) {
            if (units === 1) {
                variantIndex = 0;
            } else if ((units >= 2) && (units <= 4)) {
                variantIndex = 1;
            }
        }

        return number + ' ' + variants[variantIndex];
    },

    /**
     * Клонирует массивы и объекты
     * @param variable
     * @returns {any}
     */
    clone: function (variable) {
        let arrayOrObj = null;

        if (Array.isArray(variable)) {
            arrayOrObj = []
        } else if ($.isPlainObject(variable)) {
            arrayOrObj = {}
        }

        if (!!arrayOrObj) {
            this.forEachObj(variable, (value, key) => {
                if (typeof value === 'object') {
                    arrayOrObj[key] = this.clone(value);
                } else {
                    arrayOrObj[key] = value;
                }
            })
        }

        return !!arrayOrObj ? arrayOrObj : variable
    },

    /**
     * Преобразует строку в camelCase
     * @param {string} str
     * @param {boolean} isUpper - определяет регистр первой буквы
     * @return {*}
     */
    getCamelCase: function (str, isUpper = true) {
        if (!str.length) return str;

        /*separators = separators || ['_', '-', '/'];
        var regExp = new RegExp('[\\' + separators.join('\\') + ']([A-Za-z])', 'g');*/

        var result = str.toLowerCase().replace(/[^A-Z^a-z]([A-Za-z])/g, function (match, p1) {
            return p1.toUpperCase();
        });

        if (isUpper) {
            result = result[0].toUpperCase() + result.substr(1);
        }

        return result;
    },

    /**
     * Преобразует строку из camelCase в snake_case
     * @param {string} str
     * @return {*}
     */
    getSnakeCase: function (str) {
        return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    },

    /**
     * Возвращает идентификатор, уникальный в рамках результатов этой функции
     * @param prefix
     * @return {string}
     */
    uniqueId: function (prefix = 'generated-id-') {
        return ++lastId + prefix;
    },

    /**
     * Обходит объект аналогично методу forEach у массивов
     * @param object - объект для обхода
     * @param handler - функция-обработчик, в качестве аргументов получает значение, ключ и сам объект. Если функция вернёт false, обход завершится (будет выполнен break)
     * @param from - ключ, с которого нужно начать обход
     * @param end - ключ, на котором нужно завершить обход (данный ключ тоже не попадёт в обход)
     */
    forEachObj: function (object, handler, from = null, end = null) {
        var bActiveKey = (from === null);

        for (let key in object) {
            if ((end !== null) && (key === end)) break;

            if (key === from) {
                bActiveKey = true;
            }

            if (bActiveKey) {
                if (object.hasOwnProperty(key)) {
                    let result = handler(object[key], key, object);
                    if (result === false) {
                        break;
                    }
                }
            }
        }
    },

    /**
     * Фильтрует объект аналогично методу filter у массивов
     * @param {object} object
     * @param {function} handler
     */
    filterObj: function (object, handler) {
        const result = {};
        this.forEachObj(object, function (value, key, object) {
            if (handler(value, key, object)) {
                result[key] = value;
            }
        });

        return result;
    },

    /**
     * Возвращает массив по указанному объекту аналогично методу map у массивов
     * @param {object} object
     * @param {function} handler
     * @return {Array}
     */
    mapObj: function (object, handler) {
        let result = [];

        this.forEachObj(object, (value, key) => {
            result.push(handler(value, key, object));
        });

        return result;
    },

    /**
     * Сортирует объект по ключам
     * @param {object} object
     * @param {function} handler
     */
    sortObj: function (object, handler) {
        let result = {};

        Object.keys(object).sort(handler).forEach(key => {
            result[key] = object[key];
        });

        return result;
    },

    /**
     * Проверяет, является ли объект пустым
     * @param object
     * @returns {boolean}
     */
    isEmptyObj(object) {
        return (Object.keys(object).length === 0);
    },

    /*Проверяет равенство любых переменных.
    В основном я применяю её чтобы сравнивать объекты - сам js их сравнивать не умеет.
    Функция Helper.isEqual считает один объект равным другому, если в этих объектах одинаковые ключи и значения*/
    isEqual: function (var1, var2) {
        var result = true;

        if (((var1 !== null) && (typeof var1 === 'object')) && ((var2 !== null) && (typeof var2 === 'object'))) {
            if (Object.keys(var1).length === Object.keys(var2).length) {
                for (let key in var1) {
                    if (!var1.hasOwnProperty(key)) continue;

                    if (!this.isEqual(var1[key], var2[key])) {
                        result = false;
                        break;
                    }
                }
            } else {
                result = false;
            }
        } else {
            result = var1 === var2;
        }

        return result;
    },

    /**
     * Преобразовывает относительные ссылки к полному виду при необходимости
     * @param url{string}
     * @return {*}
     */
    resolveUrl: function (url) {
        if(this.isDev()) {
            url = 'http://' + process.env.REACT_APP_DEV_SERVER + url;
        }

        return url;
    },

    /**
     * Проверяет, включен ли режим разработки
     * @return {boolean}
     */
    isDev() {
        return (process.env.NODE_ENV === 'development');
    },

    isEnabledTestingDashboard() {
        return process.env.REACT_APP_ENABLE_TESTING_DASHBOARD === 'true';
    },

    isDisableAddedAdditionalService() {
        return process.env.REACT_APP_DISABLE_ADDED_ADDITIONAL_SERVICE === 'true';
    },

    /** Объединяет массив чисел в интервалы таким образом 14-18, 21-23, 36, 40
     * @param {array} sequence
     * @return {string}*/
    sequenceToRange: function (sequence) {
        if (sequence.length > 1) {
            const sortedSequences = _.uniq(sequence.sort((a, b) => +a - +b));
            const ranges = []
            for (let i = 0; i < sortedSequences.length; i++) {
                const start = +sortedSequences[i]
                let end = start
                if (isNaN(start)) {
                    ranges.push(sortedSequences[i])
                } else {
                    while (+sortedSequences[i + 1] - +sortedSequences[i] === 1) {
                        end = +sortedSequences[i + 1]
                        i++
                    }

                    if (end - start <= 1) {
                        ranges.push(String(start));

                        if (end !== start) {
                            ranges.push(String(end));
                        }
                    } else {
                        ranges.push(`${start}-${end}`);
                    }
                }

            }
            return ranges.join(', ')
        } else {
            return sequence[0]
        }
    },
    /**
     * Отдаёт пол с русским названием
     * @return {string}
     */
    getGender: (gender) => GENDER_OBJ?.[gender] ?? false
};

/**
 *
 * @param doctor UserMinimalData
 * @returns {boolean}
 */
export function notTrashed(doctor) {
    return doctor.deletedAt === null;
}

export default GeneralHelper

import Arr from "../../common/helpers/Arr";
import _ from 'underscore';
import Obj from "../../common/helpers/object";
import {RequiredServices, Service, Services} from "../TreatmentPlansConstructor/types";
import {RequiredForMap, RequiredForReason, Rules, ServicesQuantity} from "../../common/Service/interfaces";

export default class PlanFiller {
    private readonly rules: Rules;
    private readonly services: Services;
    private readonly getServiceDirection: GetServiceDirection;

    constructor(rules: Rules, services: Services, getServiceDirection: GetServiceDirection) {
        this.rules = rules;
        this.services = services;
        this.getServiceDirection = getServiceDirection;
    }

    /**
     * Возвращает id услуг, которые должны быть изначально добавлены в новый план
     * @return {number[]}
     */
    public addInitialServices(usedServices: Services, changeServiceQuantity: ChangeServiceQuantity) : void {
        const initialServices =  this.getRequiredServices();

        initialServices.forEach(serviceId => {
            if(!usedServices[serviceId]) {
                changeServiceQuantity(serviceId, 1);
            }
        })
    }

    public getRequiredServices(): number[] {
        return this.rules.required ? this.rules.required : [];
    }

    /**
     * Добавляет в план услуги, связанные с указанной услугой
     * @param {number} serviceId
     * @param {Service[]} usedServices
     * @param {function} changeServiceQuantity
     * @return {number[]}
     */
    public addRelatedServices(serviceId: number, usedServices: Services, changeServiceQuantity: ChangeServiceQuantity) {
        const service = this.services[serviceId];

        if(service.isAdditional) return;

        if(service.fillerRule) {
            const servicesQuantity = this.getQuantityDiff(service.fillerRule, usedServices);

            _.each(servicesQuantity, (quantity, serviceId) => {
                changeServiceQuantity(Number(serviceId), quantity);
            });
        } else {
            let relatedServices : number[] = [];

            if(service.requiredAdditionalServices) {
                relatedServices = relatedServices.concat(this.getRelatedFromRequired(service.requiredAdditionalServices));
            }

            if(service.relatedAdditionalServices) {
                relatedServices = relatedServices.concat(service.relatedAdditionalServices);
            }

            const serviceDirections = this.getServiceDirection(service);
            if(serviceDirections.length === 1) {
                const serviceDirection = serviceDirections[0];

                if (this.rules.directionsRequired) {
                    const requiredForDirection = this.rules.directionsRequired[serviceDirection];
                    if(requiredForDirection) {
                        relatedServices = relatedServices.concat(this.getRelatedFromRequired(requiredForDirection));
                    }
                }

                if (this.rules.directionsRelated) {
                    const relatedForDirection = this.rules.directionsRelated[serviceDirection];
                    if(relatedForDirection) {
                        relatedServices = relatedServices.concat(relatedForDirection);
                    }
                }
            }

            relatedServices.forEach(relatedServiceId => {
                if(!usedServices[relatedServiceId]) {
                    changeServiceQuantity(relatedServiceId, 1);
                }
            });
        }
    }

    /**
     * Удаляет из плана услуги, связанные с данной услугой
     * @param serviceId
     * @param usedServices
     * @param changeServiceQuantity
     */
    public deleteRelatedServices(serviceId: number, usedServices: Services, changeServiceQuantity: ChangeServiceQuantity): void {
        const service = this.services[serviceId];

        if(!service.isAdditional && service.fillerRule) {
            const servicesQuantity = this.getQuantityDiff(service.fillerRule, usedServices, false);
            _.each(servicesQuantity, (quantity, serviceId) => {
                changeServiceQuantity(Number(serviceId), -quantity);
            });
        }
    };

    public getRequiredForMap(usedServices: Services, usedDirections: string[]) : RequiredForMap {
        const result: RequiredForMap = {};

        /* --- составляем карту обязательных доп услуг для используемых услуг --- */

        const requiredForServicesMap: {[serviceId: number]: number[]} = {};

        _.each(usedServices, usedService => {
            if(usedService.isAdditional || !usedService.requiredAdditionalServices || !usedService.isEnabled) return;

            usedService.requiredAdditionalServices.forEach(requiredService => {
                if(Array.isArray(requiredService)) return;

                if(!requiredForServicesMap[requiredService]) {
                    requiredForServicesMap[requiredService] = [];
                }

                requiredForServicesMap[requiredService].push(usedService.id);
            });
        });

        /* --- составляем карту обязательных услуг для используемых направлений --- */

        const requiredForDirectionsMap: RequiredForDirectionsMap = {};

        if(this.rules.directionsRequired !== null) {
            usedDirections.forEach(direction => {
                if(this.rules.directionsRequired === null) return; // чтобы успокоился TS

                const requiredServices = this.rules.directionsRequired[direction];
                if(!requiredServices) return;

                requiredServices.forEach(requiredService => {
                    if(Array.isArray(requiredService)) {
                        const usedAlternativeServices = requiredService.filter(alternativeServiceId => alternativeServiceId in usedServices);
                        if(usedAlternativeServices.length === 1) {
                            const usedAlternativeServiceId = usedAlternativeServices[0];

                            if(!requiredForDirectionsMap[usedAlternativeServiceId]) {
                                requiredForDirectionsMap[usedAlternativeServiceId] = [];
                            }

                            requiredForDirectionsMap[usedAlternativeServiceId].push({
                                code: direction,
                                alternatives: _.without(requiredService, usedAlternativeServiceId)
                            });
                        }
                    } else {
                        if(!requiredForDirectionsMap[requiredService]) {
                            requiredForDirectionsMap[requiredService] = [];
                        }

                        requiredForDirectionsMap[requiredService].push({
                            code: direction
                        });
                    }
                })
            });
        }

        /* --- составляем карту обязательных доп услуг --- */

        const requiredAdditionalServicesMap: {[serviceId: number]: number} = this.rules.required ? Arr.flip(this.rules.required) : {};

        /* --- собираем всё в единую карту --- */

        _.filter(usedServices, usedService => usedService.isAdditional).forEach(additionalService => {
            const requiredForReasons: RequiredForReason[] = [];

            if(additionalService.id in requiredAdditionalServicesMap) {
                requiredForReasons.push({
                    type: 'is_required'
                });
            }


            const requiredForDirections = requiredForDirectionsMap[additionalService.id];
            if(!!requiredForDirections) {
                const reason: RequiredForReason = {
                    type: 'direction',
                    params: {
                        directions: requiredForDirections.map(direction => direction.code),
                    }
                };

                const alternatives: {[directionCode: string]: number[]} = {};

                requiredForDirections.forEach(direction => {
                    if(direction.alternatives) {
                        alternatives[direction.code] = direction.alternatives;
                    }
                });

                if(_.size(alternatives) > 0) {
                    reason.params.alternatives = alternatives;
                }

                requiredForReasons.push(reason);
            }

            if(requiredForServicesMap[additionalService.id]) {
                requiredForReasons.push({
                    type: 'service',
                    params: {
                        servicesIds: Arr.unique(requiredForServicesMap[additionalService.id])
                    }
                });
            }

            if(requiredForReasons.length > 0) {
                result[additionalService.id] = requiredForReasons;
            }
        });

        return result;
    }

    private getRelatedFromRequired(requiredServices: RequiredServices) : number[] {
        const relatedServices: number[] = [];

        requiredServices.forEach(requiredService => {
            if(!Array.isArray(requiredService)) {
                relatedServices.push(requiredService);
            }
        });

        return relatedServices;
    }

    private getQuantityDiff(quantityRuleCode: string, usedServices: Services, checkUsedAdditionalServices: boolean = true): ServicesQuantity {
        const quantityRule = this.rules.quantityRules[quantityRuleCode];

        const appliedRules = _.uniq(_.pluck(usedServices as any, 'fillerRule'));

        if(appliedRules.includes(quantityRule.code)) return {};

        let servicesQuantity: {[serviceId: string]: number};
        const ruleConfig = quantityRule.config;
        const hasOnlyAdditionalServices = _.every(usedServices, service => service.isAdditional);

        if(hasOnlyAdditionalServices || (ruleConfig.complement && ruleConfig.complement.length)) {
            // если в плане врача только доп услуги - не прибавляем количество, а дополняем количество до необходимого
            const usedServicesQuantity = _.mapObject(usedServices, service => service.quantity);
            let subtractQuantity = {};

            if(hasOnlyAdditionalServices && !checkUsedAdditionalServices) {
                subtractQuantity = Obj.fill(this.getRequiredServices(), 1);
            } else if(ruleConfig.complement) {
                const rulesToComplement = _.intersection(ruleConfig.complement, appliedRules);
                let rulesToComplementSum = {};

                rulesToComplement.forEach(ruleCode => {
                    const rule = this.rules.quantityRules[ruleCode];
                    rulesToComplementSum = Obj.mergeSum(rulesToComplementSum, rule.config.quantity)
                });

                subtractQuantity = checkUsedAdditionalServices ? Obj.limit(usedServicesQuantity, rulesToComplementSum) : rulesToComplementSum;
            }

            servicesQuantity = Obj.subtractObjects(quantityRule.config.quantity, subtractQuantity);
        } else {
            // иначе просто добавляем количество, предусмотренное по правилу
            servicesQuantity = quantityRule.config.quantity;
        }

        return Obj.filter(servicesQuantity, (quantity: number) => quantity > 0) as ServicesQuantity;
    }

    getServiceRequiredServices(serviceId: number): RequiredServices|null {
        // TODO перенести в более подходящее место, отрефакторить PlanFiller
        const service = this.services[serviceId];
        let requiredAdditionalServices: RequiredServices|null = null;

        if (service.requiredAdditionalServices === null) {
            /*if (this.rules.directionsRequired && this.rules.directionsRequired[direction]) {
                requiredAdditionalServices = this.rules.directionsRequired[direction];
            }*/
        } else {
            requiredAdditionalServices = service.requiredAdditionalServices;
        }

        return requiredAdditionalServices;
    }
}


type GetServiceDirection = (service: Service) => string[];

type ChangeServiceQuantity = (serviceId: number, quantity: number) => void;


type RequiredForDirectionsMap = {
    [serviceId: number]: {
        code: string,
        alternatives?: number[]
    }[]
}

import {useEffect, useRef, useState} from "react";
import ServerCommand from "../../../common/server/server-command";
import {allDoctors, pageSize} from "../data";
import {IFilter, StatePatientList} from "../types/common";
import {PatientValues} from "../../../containers/PatientField";
import {useLazyLoading} from "../../../common/hooks/useLazyLoading";
import Helper from "../../../common/helpers/main";

const usePatientList = () => {
    const refScrollList = useRef(null) as any
    const [state, setState] = useState<StatePatientList>({
        patientsList: null,
        doctor: allDoctors,
        birthday: null,
        searchQuery: '',
        page: 0,
        isAllDataLoaded: false,
        serverFilter: null
    })

    const loadData = (nextPage: boolean = false) => {

        /*Если все подгрузки были не завершены или
        * если текущий фильр не равен серверному фильтру и
        * новый фильр не равен старому фильтру*/
        if (!state.isAllDataLoaded) {
            const currentFilter: IFilter = {};
            if (!!state.searchQuery) currentFilter.searchQuery = state.searchQuery;
            if (!!state.doctor.value) currentFilter.doctorId = state.doctor.value;
            if (!!state.birthday) currentFilter.personalBirthday = Helper.Date.formatDateForServer(state.birthday);

            const page = nextPage ? state.page + 1 : 0;  // Количество страниц
            const data = {
                offset: page * pageSize,  // лимит умноженый на количество страниц
                limit: pageSize, // лимит данных для загрузки
                countTotal: true,
                filter: currentFilter,
                getPatientsDoctors: true,
                select: ['number', 'personalBirthday', 'personalCity', 'personalHome', 'personalHousing', 'personalStreet', 'personalPhone', 'personalApartment', 'lastName', 'name', 'secondName']
            };

            ServerCommand.get('patient/list', data).then((response) => {
                setState(prevState => {
                    let patientsList: PatientValues[] = []
                    if (nextPage) {
                        let prevPatientsList: PatientValues[] = [];
                        if (!!prevState.patientsList) prevPatientsList = prevState.patientsList
                        patientsList = [...prevPatientsList, ...response.list]
                    } else {
                        patientsList = response.list
                    }

                    return {
                        ...prevState,
                        patientsList,
                        page,
                        isAllDataLoaded: response.count === patientsList.length,
                        serverFilter: currentFilter
                    }
                })
            });
        }

    };

    useEffect(() => loadData(), [state.doctor.value, state.birthday, state.searchQuery])

    const onScroll = useLazyLoading({
        onIntersection: () => loadData(true),
        containerRef: refScrollList
    })

    return {
        state,
        setState,
        loadData,
        refScrollList,
        onScroll
    }

};

export default usePatientList;

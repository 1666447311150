import React from 'react';
import Paper from "../../containers/UI-Collection/Elements/Surfaces/Paper";
import Typography from "../../components-ui/DataDisplay/Typography/Typography";
import Box from '../../components-ui/Layout/Box';
import PatientCard from "../../content/PatientCard/PatientCard";
import Table from "../../components-ui/DataDisplay/Table";
import {useDialogsManager} from "../../containers/DialogsManager/DialogsManager";
import useColumns from "./hooks/useColumns";
import usePatientList from "./hooks/usePatientList";
import Header from "./components/Header";
import useRows from "./hooks/useRows";
import LoaderPage from '../../components/LoaderPage';

const PatientList = () => {
    const {showSlideModal} = useDialogsManager()
    const columns = useColumns()
    const {state, setState, refScrollList, onScroll} = usePatientList();
    const rows = useRows({state})

    if (rows === null) return <LoaderPage/>

    return (
        <Box padding="28px 32px 48px 33px">
            <Paper isHidden={false} padding="30px 27px" zIndex={1}>
                <Typography variant="title" children="Картотека" fontSize="2rem" margin="0 0 26px"/>
                <Table columns={columns}
                       rows={rows}
                       height="566px"
                       refScrollList={refScrollList}
                       onScroll={onScroll}
                       onClickRow={(row) => showSlideModal(<PatientCard patientId={row.id}/>)}
                       header={<Header state={state} setState={setState}/>}/>
            </Paper>
        </Box>
    );
}

export default PatientList;

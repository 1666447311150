import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import Box from "../../../components-ui/Layout/Box";
import Select from "../../../components-ui/Inputs/Select/Select";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import Button from "../../../components-ui/Inputs/Button/Button";
import {StatePatientList} from "../types/common";
import {useSelector} from "react-redux";
import Helper from "../../../common/helpers/main";
import {allDoctors} from "../data";
import useDebounce from "../../../common/hooks/useDebounce";
import {withoutTrashed} from "../../../common/helpers/Doctor";
import DateTime from "../../../components-ui/Inputs/DateTime/DateTime";

interface Props {
    state: StatePatientList
    setState: Dispatch<SetStateAction<StatePatientList>>
}

const Header = ({state, setState}: Props) => {
    const [search, setSearch] = useState(state.searchQuery);
    const debounceSearch = useDebounce(search, 500)
    useEffect(() => {
        setState(prevState => ({...prevState, searchQuery: debounceSearch}))
    }, [debounceSearch])

    const doctors = useSelector(state => state.common.doctors);
    const options = useMemo(() => {
        return [allDoctors, ...withoutTrashed(doctors).map(doctor => {
            return {
                value: doctor.id,
                label: Helper.getFio(doctor),
                color: doctor.color
            }
        })]
    }, [])

    return (
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center"
             padding="16px 28px 16px 20px">
            <Select placeholder="Все"
                    label='Врач:'
                    variant="colored"
                    value={state.doctor}
                    options={options}
                    onChange={doctor => setState(prevState => ({...prevState, doctor}))}
            />
            <DateTime onChange={birthday => setState(prevState => ({...prevState, birthday}))}
                      selected={state.birthday}
                      placeholderText="День рождения"
                      dateFormat="d MMMM"
                      margin="0 0 0 20px"
            />
            <TextField onChange={setSearch}
                       isSearch={true}
                       widthInput="100%"
                       margin="0 20px"
                       placeholder="Поиск пациента по номеру карты, ФИО или телефону"
                       value={search}/>
            <Button variant={(state.searchQuery.length > 0 || state.doctor.value || state.birthday) ? 'standard' : 'disabled'}
                    children='Сбросить'
                    size="md"
                    onClick={() => {
                        setState(prevState => ({...prevState, doctor: allDoctors, birthday: null, searchQuery: ''}))
                        setSearch('')
                    }}/>
        </Box>
    );
};

export default Header;
